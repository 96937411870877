import React from 'react';
// import { graphql } from 'gatsby';
import Layout from '../components/Layout';

const ContactPage = () => {
  return (
    <Layout pathName="Contact">
      <h1>Contact Page</h1>
    </Layout>
  );
};

export default ContactPage;
